import React from "react";
import Link from "gatsby-link";

import {
  Jumbotron,
  Grid,
  Row,
  Col,
  Image,
  Button,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Tabs,
  Tab,
  TabContainer,
  TabContent,
  TabPane
} from "react-bootstrap";
import AltHeader from "../../components/alt-header.js";
import UpNext from "../../components/up-next.js";
import Layout from "../../components/Layout";
import JobHeader from "../../components/job-header.js";
const MlifePage = () => (
  <Layout>
    <JobHeader
      image="/img/mlife-header3.jpg"
      title="M Life"
      subtitle="Website redesign for a Health & Beauty brand"
      text="Web Design / Design Lead / Prototyping / Framer"
    />

    <Row className="show-grid side-padding intro">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <h2>My Role</h2>
        <p>
          <em>
            My main role in this project was to be the Design Lead. I worked on
            the design whilst reporting to my manager and presented it to the
            COO of A.S Watson, the business units and various suppliers
            including Chanel. Whilst working on the project I took out user
            tests by creating high fidelity prototypes in Framer. I also lead
            various sub projects, including designing new emails and a new
            version of the blog, where I managed the work loads and time of
            other memebers of the design team.{" "}
          </em>
        </p>
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={12}>
        <Image src="/img/mlife/mlife-home.jpg" />
      </Col>
    </Row>
    <Row className="show-grid">
      <Col xs={12} md={12}>
        <Image src="/img/mlife/mlife-category.jpg" />
      </Col>
    </Row>
    <Row className="show-grid">
      <Col xs={12} md={12}>
        <Image src="/img/mlife/mlife-article2.jpg" />
      </Col>
    </Row>

    <UpNext
      image1="/img/marionnaud-card2.jpg"
      title1="Marionnaud"
      text1="A European Health & Beauty brand"
      url1="/marionnaud/"
      image2="/img/perfumeshop-card.jpg"
      title2="The Perfume Shop"
      text2="A fragrance specialist based in the UK"
      url2="/perfume-shop/"
      image3="/img/spread-co-card.jpg"
      title3="Spread Co"
      text3="A financial spread betting company"
      url3="/spread-co/"
    />
  </Layout>
);

export default MlifePage;
